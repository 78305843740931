


















import { ClientFlashMessageType } from '~/models/flash/types'
import AlreadyLoggedInAlert from '~/components/shared/flash/alert/AlreadyLoggedInAlert.vue'
import { CLIENT_FLASH_MESSAGE_COOKIE_NAME } from '~/constants/shared'
import GuestRegisterAlert from '~/components/shared/flash/alert/GuestRegisterAlert.vue'
import PassResetAlert from '~/components/shared/flash/alert/PassResetAlert.vue'
import {
  computed,
  defineComponent,
  onMounted,
  toRefs,
  useRoute
} from '@nuxtjs/composition-api'
import { useApp } from '~/compositions/app'
import { useSnackbar } from '~/compositions/snackbar'
import { useCookies } from '~/compositions/useCookies'

export default defineComponent({
  components: {
    PassResetAlert,
    GuestRegisterAlert,
    AlreadyLoggedInAlert
  },
  props: {
    clientFlashMessage: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { clientFlashMessage } = toRefs(props)

    const { isPlot } = useApp()
    const route = useRoute()
    const snackbar = useSnackbar()
    const cookies = useCookies()

    const absolute = computed(() => {
      return (
        isPlot.value &&
        (route.value.name?.startsWith('__account') ||
          route.value.name === '__index')
      )
    })

    const showAsSnackbar = computed(() => {
      return Boolean(flashData.value?.showAsSnackbar)
    })

    const flashData = computed(() => {
      return (
        clientFlashMessage.value?.data || {
          text: '',
          type: ClientFlashMessageType.PASSWORD_RESET_SUCCESS
        }
      )
    })

    function isOfType(type: ClientFlashMessageType) {
      return type === clientFlashMessage.value?.type
    }

    function showSnackbar() {
      const text = flashData.value?.text
      if (!text) {
        return
      }

      switch (flashData.value?.snackbarVariant) {
        case 'success': {
          snackbar.success(text)
          break
        }
        case 'error': {
          snackbar.error(text)
          break
        }
        default: {
          snackbar.info(text)
        }
      }
    }

    onMounted(() => {
      if (showAsSnackbar.value) {
        showSnackbar()
      }

      cookies.delete(CLIENT_FLASH_MESSAGE_COOKIE_NAME)
    })

    return {
      flashData,
      showAsSnackbar,
      isPlot,
      absolute,
      isOfType,
      ClientFlashMessageType
    }
  }
})
