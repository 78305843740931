




















































































import { computed, defineComponent } from '@nuxtjs/composition-api'
import CDropdown from '~/components/shared/configurable/dropdown/CDropdown.vue'
import { useDep } from '~/compositions/dependency-container'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { ciCredit as ciCreditSolid } from '~/icons/source/solid/credit'
import { ciCredit as ciCreditRegular } from '~/icons/source/regular/credit'
import { ciExclamationCircle } from '~/icons/source/solid/exclamation-circle'
import { formatNumber } from '~/utils/number'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  components: {
    CDropdown
  },
  setup() {
    const { getters } = useNamespacedStore<UserState>(USER_NS)
    const legacyUrlService = useDep(LegacyUrlService)

    const noPaidCredits = computed(() => getters('paidCredits') === 0)
    const paidCreditsFormatted = computed(() =>
      formatNumber(getters('paidCredits'))
    )
    const freeCreditsFormatted = computed(() =>
      formatNumber(getters('freeCredits'))
    )
    const buyCreditsUrl = computed(() => legacyUrlService.getBuyCreditsUrl())

    return {
      ciCreditSolid,
      ciCreditRegular,
      ciExclamationCircle,
      noPaidCredits,
      paidCreditsFormatted,
      freeCreditsFormatted,
      buyCreditsUrl
    }
  },
  i18n: defineComponentTranslations({
    'no paid credits': {
      en: 'No paid credits',
      el: 'Δεν έχεις αγορασμένα credits'
    }
  })
})
