import { CIcon } from '~/icons/types'
export const ciEshop: CIcon = {
  width: 24,
  height: 25,
  paths: [
    {
      d:
        'M24 8.113V6.472a.747.747 0 0 0-.117-.399L21.89 3.027a.722.722 0 0 0-.605-.329H2.719a.716.716 0 0 0-.605.329L.122 6.073a.747.747 0 0 0-.117.399v1.64a2.594 2.594 0 0 0 1.875 2.494v9.643H.727a.727.727 0 0 0 0 1.453h22.546a.727.727 0 0 0 0-1.454h-1.082v-9.66A2.602 2.602 0 0 0 24 8.113ZM3.108 4.152h17.78l1.045 1.593H2.067l1.04-1.593Zm15.684 3.046v.915a1.15 1.15 0 0 1-1.148 1.148h-.014a1.15 1.15 0 0 1-1.149-1.148v-.915h2.311Zm-3.76 0v.915a1.15 1.15 0 0 1-1.148 1.148h-.014a1.15 1.15 0 0 1-1.148-1.148v-.915h2.31Zm-3.759 0v.915a1.15 1.15 0 0 1-1.148 1.148h-.014a1.15 1.15 0 0 1-1.149-1.148v-.915h2.311Zm-3.754 0v.915A1.15 1.15 0 0 1 6.37 9.26h-.014a1.15 1.15 0 0 1-1.148-1.148v-.915h2.31ZM2.597 9.256a1.15 1.15 0 0 1-1.149-1.148v-.914h2.307v.914a1.15 1.15 0 0 1-1.149 1.148h-.01Zm7.444 10.992H6.136l.01-6.515h3.904l-.01 6.515Zm10.701 0h-9.248l.01-7.237a.72.72 0 0 0-.722-.727H5.422a.726.726 0 0 0-.726.722l-.01 7.242H3.329v-9.642c.445-.126.84-.37 1.153-.698a2.587 2.587 0 0 0 1.87.801h.015c.736 0 1.397-.309 1.87-.801a2.587 2.587 0 0 0 1.87.801h.014c.736 0 1.397-.309 1.87-.801a2.587 2.587 0 0 0 1.87.801h.015c.736 0 1.397-.309 1.87-.801a2.587 2.587 0 0 0 1.87.801h.015c.736 0 1.397-.309 1.87-.801.328.342.75.595 1.223.717v9.623h.02Zm.661-10.992h-.014a1.15 1.15 0 0 1-1.148-1.148v-.914h2.306v.914a1.14 1.14 0 0 1-1.144 1.148Z'
    },
    {
      d:
        'M18.637 12.284h-4.42a.727.727 0 0 0-.727.727v3.605c0 .398.324.726.727.726h4.42a.727.727 0 0 0 .727-.726V13.01a.724.724 0 0 0-.727-.727Zm-.722 3.605h-2.967v-2.156h2.967v2.156Z'
    }
  ],
  name: 'e-shop',
  type: 'regular'
}
