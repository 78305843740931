
























































import { defineComponent } from '@nuxtjs/composition-api'
import { ciCredit } from '~/icons/source/solid/credit'
import { ciCredit as ciCreditRegular } from '~/icons/source/regular/credit'
import { formatNumber } from '~/utils/number'
import { mapGetters } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import RouteGuardService from '~/services/RouteGuardService'
import { mapDeps } from '~/plugins/dependency-container/utils'
import CreditsTable from '~/components/shared/account/payments/credits/CreditsTable.vue'
import CCountTo from '~/components/shared/configurable/CCountTo.vue'

export default defineComponent({
  components: {
    CCountTo,
    CreditsTable
  },
  data() {
    return {
      startVal: null,
      triggerAnimation: false
    }
  },
  watch: {
    totalCredits(val, oldVal) {
      if (val !== oldVal) {
        this.startVal = oldVal

        if (this.triggerAnimation) {
          this.triggerAnimation = false
          setTimeout(() => {
            this.triggerAnimation = true
          })
        } else {
          this.triggerAnimation = true
        }
      }
    }
  },
  computed: {
    ...mapDeps({
      legacyUrlService: LegacyUrlService,
      routeGuardService: RouteGuardService
    }),
    ...mapGetters(USER_NS, {
      totalCredits: 'totalCredits',
      paidCredits: 'paidCredits',
      freeCredits: 'freeCredits'
    }),
    icons() {
      return {
        credit: ciCredit,
        creditRegular: ciCreditRegular
      }
    },
    buyCreditsUrl() {
      return this.legacyUrlService.getBuyCreditsUrl()
    },
    canBuyCredits() {
      return this.routeGuardService.userCanPay()
    },
    paidMode() {
      return (
        this.canBuyCredits && this.totalCredits !== 0 && this.paidCredits > 0
      )
    },
    formatedTotalCredits() {
      return formatNumber(this.totalCredits || 0)
    }
  }
})
