












import Vue from 'vue'
import AppFooter from '~/components/shared/footer/AppFooter.vue'
import {
  faFacebookSquare,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { mapGetters } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import UserAgentService from '~/services/UserAgentService'

export default Vue.extend({
  components: { AppFooter },
  props: {
    availableLocales: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(USER_NS, {
      isAnon: 'isAnon',
      isAgent: 'isOfTypeDealer'
    }),
    ...mapDeps({
      userAgentService: UserAgentService
    }),
    icons() {
      return {
        facebook: faFacebookSquare,
        instagram: faInstagram,
        youtube: faYoutube
      }
    },
    footerMenu() {
      return [
        {
          column_menus: [
            {
              menu_title: 'Plot',
              mobile_collapsible: true,
              links: [
                {
                  title: this.$t('about us'),
                  url: '/information/faq/company/'
                },
                {
                  title: this.$t('terms and conditions'),
                  url: '/information/faq/terms/'
                },
                {
                  title: this.isAgent
                    ? this.$t('new classified')
                    : this.$t('free classified'),
                  url: '/classifieds/new/'
                },
                {
                  title: this.$t('support'),
                  url: '/information/contact/'
                },
                {
                  title: this.$t('property prices in greece'),
                  url: '/price-trends/'
                }
              ]
            }
          ]
        },
        {
          column_menus: [
            {
              menu_title: this.$t('agents'),
              mobile_collapsible: true,
              links: [
                {
                  title: this.$t('register'),
                  url: '/register/',
                  hide: !this.isAnon
                },
                {
                  title: this.$t('login'),
                  url: '/login/',
                  hide: !this.isAnon
                },
                {
                  title: this.$t('find an agent'),
                  url: '/agents/'
                },
                {
                  title: this.$t('frequently asked questions'),
                  url: '/information/faq/agents/'
                },
                {
                  title: this.$t('agent services'),
                  url: '/information/faq/services_agents/'
                }
              ]
            }
          ]
        },
        {
          column_menus: [
            {
              menu_title: this.$t('private sellers'),
              mobile_collapsible: true,
              links: [
                {
                  title: this.$t('register'),
                  url: '/guests/register/',
                  hide: !this.isAnon
                },
                {
                  title: this.$t('login'),
                  url: '/login/',
                  hide: !this.isAnon
                },
                {
                  title: this.$t('find an agent'),
                  url: '/agents/'
                },
                {
                  title: this.$t('frequently asked questions'),
                  url: '/information/faq/users/'
                },
                {
                  title: this.$t('private seller services'),
                  url: '/information/faq/services_users/'
                }
              ]
            }
          ]
        },
        {
          extra_classes: 'socials-and-apps',
          column_menus: [
            {
              menu_title: 'Follow us',
              mobile_collapsible: false,
              links: [
                {
                  title: 'Facebook',
                  hide_title: true,
                  url: 'https://www.facebook.com/Plot.gr/',
                  icon: { type: 'icon', icon: this.icons.facebook },
                  target: '_blank',
                  rel: 'noreferrer'
                },
                {
                  title: 'Instagram',
                  hide_title: true,
                  url: 'https://www.instagram.com/plot.gr/',
                  icon: { type: 'icon', icon: this.icons.instagram },
                  target: '_blank',
                  rel: 'noreferrer'
                },
                {
                  title: 'YouTube',
                  hide_title: true,
                  url:
                    'https://www.youtube.com/channel/UCb6Xyn0pGKXcPV0YuWFumEw',
                  icon: { type: 'icon', icon: this.icons.youtube },
                  target: '_blank',
                  rel: 'noreferrer'
                }
              ]
            },
            {
              menu_title: 'Mobile apps',
              mobile_collapsible: false,
              links_class: 'apps',
              links: [
                {
                  title: 'Android',
                  hide_title: true,
                  url:
                    'https://play.google.com/store/apps/details?id=com.plot.gr',
                  target: '_blank',
                  icon: {
                    type: 'img',
                    class: 'playstore',
                    src: require('~/assets/img/brands/google-play-store.svg'),
                    width: 27,
                    height: 27
                  },
                  extra_classes: !this.userAgentService.isAndroidOs()
                    ? 'max-sm:!tw-hidden'
                    : null,
                  rel: 'noreferrer'
                },
                {
                  title: 'iOS',
                  hide_title: true,
                  url: 'https://apps.apple.com/app/plot-gr/id1628294378',
                  target: '_blank',
                  icon: {
                    type: 'img',
                    src: require('~/assets/img/brands/apple-ios-store.svg'),
                    width: 27,
                    height: 27
                  },
                  extra_classes: !this.userAgentService.isIos()
                    ? 'max-sm:!tw-hidden'
                    : null,
                  rel: 'noreferrer'
                }
              ]
            }
          ]
        }
      ]
    }
  }
})
