import { CIcon } from '~/icons/types'
export const ciOrdersBox: CIcon = {
  width: 25,
  height: 25,
  paths: [
    {
      d:
        'M24.358 5.211 13.094 1.185a.725.725 0 0 0-.488 0L1.342 5.21a.723.723 0 0 0-.483.684V18.9c0 .29.174.553.441.665l11.264 4.828a.764.764 0 0 0 .286.061.752.752 0 0 0 .3-.065l11.264-5.068a.72.72 0 0 0 .427-.66V5.895a.734.734 0 0 0-.483-.684ZM12.85 9.392 9.414 8.09l8.944-3.478 3.651 1.308-9.159 3.473Zm0-6.75 2.813 1.003-8.87 3.446-3.102-1.177 9.159-3.272ZM2.313 6.95l3.951 1.5v6.9l1.875.75V9.163l3.98 1.509V22.63l-9.806-4.21V6.95Zm11.264 15.656V10.667l9.81-3.722v11.246l-9.81 4.415Z'
    }
  ],
  name: 'orders-box',
  type: 'regular'
}
