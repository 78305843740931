













import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { FLASH_NS } from '~/store/modules/shared/flash/state'
import { BackendFlashMessageVariant } from '~/models/flash/types'
import { BACKEND_FLASH_MESSAGE_COOKIE_NAME } from '~/constants/shared'
import { APP_NS } from '~/store/modules/shared/app/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'

export default Vue.extend({
  data() {
    return {
      hide: false
    }
  },
  computed: {
    ...mapState(FLASH_NS, {
      backendFlashMessage: state => state.backendFlashMessage
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    inPanel() {
      return this.$route.name.startsWith('__account')
    },
    absolute() {
      return this.isPlot && (this.$route.name === '__index' || this.inPanel)
    },
    show() {
      const { message } = this
      return message && message !== 'null' && message !== 'None'
    },
    variant() {
      return this.backendFlashMessage?.variant
    },
    message() {
      return this.backendFlashMessage?.message
    },
    alertVariant() {
      switch (this.variant) {
        case BackendFlashMessageVariant.OK:
        case BackendFlashMessageVariant.SUCCESS: {
          return 'success'
        }
        case BackendFlashMessageVariant.WARNING: {
          return 'warning'
        }
        case BackendFlashMessageVariant.ERROR:
        case BackendFlashMessageVariant.DANGER: {
          return 'danger'
        }
        default: {
          return 'info'
        }
      }
    }
  },
  mounted() {
    this.$cookies.delete(BACKEND_FLASH_MESSAGE_COOKIE_NAME)
  },
  methods: {
    onAlertClick() {
      if (this.isMobile) {
        this.hide = true
      }
    }
  }
})
